import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { DeleteTradingDocumentBtn } from "pages/tradingDocuments/shared/components/deleteTradingDocumentBtn/DeleteTradingDocumentBtn";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { useNavigate } from "hooks/useNavigate";
import { DownloadTradingDocumentXml } from "pages/tradingDocuments/shared/components/downloadTradingDocumentXml/DownloadTradingDocumentXml";
import { DownloadFakir } from "pages/tradingDocuments/shared/components/DownloadFakir";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();

  if (!tradingDocument) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <ConfirmCheckbox tradingDocument={tradingDocument} />
          <div>Faktura VAT&nbsp;{tradingDocument.signature}</div>
        </SlidingHeaderWrapper>
      ) : (
        <ConfirmTradingDocument tradingDocument={tradingDocument} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <DownloadTradingDocumentXml tradingDocument={tradingDocument} />
        <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
        <DownloadRevisor tradingDocumentIds={[tradingDocument.id]} />
        <DownloadFakir tradingDocumentIds={[tradingDocument.id]} />
        <span className="divider line-divider" />
        <Tooltip title="Wyświetl historię korekty">
          <IconButton
            icon={MdiHistory}
            onClick={() =>
              navigate(`/finances/corrections/${panelId}/history`, {
                tradingDocument,
              })
            }
            variant="transparent"
          />
        </Tooltip>
        <span className="divider line-divider" />
        <DeleteTradingDocumentBtn
          close={close}
          tradingDocumentId={tradingDocument.id}
          tradingDocumentSignature={tradingDocument.signature}
          tradingDocumentStatus={tradingDocument.status}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};

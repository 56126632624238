import { Marker as MapMarker, Polygon, Polyline } from "@react-google-maps/api";
import { useCallback, useMemo, useRef, useState } from "react";
import { throttle as throttleFunc } from "throttle-debounce";
import { MapMode } from "../Creator";
import { assertIsDefined } from "utilities/assertIsDefined";

type LatLng = { lat: number; lng: number };
export type PolygonController = ReturnType<typeof useDragPolygonController>;

interface Props {
  path: { lat: number; lng: number }[];
  onDrag: (e: google.maps.MouseEvent, index: number) => void;
  onDoubleClick: (index: number) => void;
  mode: MapMode;
}

export const DragPolygon = ({ path, onDrag, onDoubleClick, mode }: Props) => {
  function getPaths() {
    if (path.length === 0) {
      return [[], []] as LatLng[][];
    }
    const last = Object.values(path[path.length - 1] || {});
    const isPathClosed = Object.values(path[0]).every((coords, index) => last[index] === coords);
    const closedPath = isPathClosed ? path : [...path, path[0]];
    const openPath = isPathClosed && path.length > 1 ? path.slice(0, path.length - 1) : path;
    return [openPath, closedPath] as const;
  }

  const handleDrag = useRef<typeof onDrag>(throttleFunc(100, onDrag));

  const [openPath, closedPath] = getPaths();
  if (path.length === 0) return null;
  return (
    <>
      <Polygon
        options={{
          strokeWeight: 0,
          strokeColor: "#765ee3",
          fillColor: "#765ee3",
          fillOpacity: mode === "polygon" ? 0.5 : 0.2,
        }}
        path={openPath}
      />
      <Polyline
        path={closedPath}
        options={{
          strokeOpacity: mode === "polygon" ? 0 : 1,
          strokeWeight: 1,
          strokeColor: "#765ee3",
          icons:
            mode === "polygon"
              ? [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      fillColor: "#765ee3",
                      strokeColor: "#765ee3",
                      strokeOpacity: 1,
                      scale: 1.5,
                    },
                    offset: "0",
                    repeat: "10px",
                  },
                ]
              : undefined,
        }}
      />
      {mode === "polygon" &&
        openPath.map((coords, index) => {
          return (
            <MapMarker
              key={index}
              draggable
              position={coords}
              onDrag={e => handleDrag.current(e, index)}
              onDblClick={e => onDoubleClick(index)}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                strokeOpacity: 1,
                scale: 3.5,
                strokeColor: "#765ee3",
              }}
            />
          );
        })}
    </>
  );
};

export function useDragPolygonController() {
  const [path, setPath] = useState<LatLng[]>([]);
  const modifyPoint = useCallback(
    (coords: LatLng, index: number) => {
      setPath(p => {
        const newPath = [...p];
        newPath.splice(index, 1, coords);
        return newPath;
      });
    },
    [setPath],
  );

  const deletePoint = useCallback(
    (index: number) => {
      setPath(p => {
        const newPath = [...p];
        newPath.splice(index, 1);
        return newPath;
      });
    },
    [setPath],
  );

  const addPoint = useCallback((coords: LatLng) => setPath(p => [...p, coords]), [setPath]);
  const reset = useCallback(() => setPath([]), [setPath]);
  const onDrag = useCallback(
    (e: google.maps.MouseEvent, index: number) => {
      assertIsDefined(e.latLng);
      modifyPoint({ lat: e.latLng.lat(), lng: e.latLng.lng() }, index);
    },
    [modifyPoint],
  );
  const handleAddPoint = useCallback(
    (e: google.maps.MouseEvent) => {
      assertIsDefined(e.latLng);
      addPoint({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    },
    [addPoint],
  );

  return useMemo(
    () => ({ modifyPoint, addPoint, path, deletePoint, reset, onDrag, handleAddPoint }),
    [path, modifyPoint, addPoint, deletePoint, reset, onDrag, handleAddPoint],
  );
}

import { assertIsDefined } from "utilities/assertIsDefined";
import { useChangeUserCenterPoint } from "./useChangeUserCenterPoint";
import { useAssignItem } from "../../hooks/useAssignItem";
import { useRouteViewState } from "../../routeCreatorState";
import { useCreateUserCenterPoint } from "./useCreateUserCenterPoint";
import { useSelector } from "hooks";
import { Route } from "api/routes/models";
import { MapMode } from "../../Creator";
import { PolygonController } from "../DragPolygon";
import { useInfoWindow } from "../../hooks/useInfoWindow";

export const useOnMapClick = ({
  route,
  mapMode,
  polygonController,
}: {
  route: Route;
  mapMode: MapMode;
  polygonController: PolygonController;
}) => {
  const actions = useRouteViewState("slave", store => store.actions);
  const { closeInfoWindow } = useInfoWindow();
  const editingPasspointId = useRouteViewState("slave", store => store.editingPasspointId);
  const isSettingCenterPoint = useRouteViewState("slave", store => store.isSettingCenterPoint);
  const { assignOrder } = useAssignItem(route);
  const saveUserCenterPoint = useCreateUserCenterPoint();
  const changeUserCenterPoint = useChangeUserCenterPoint();
  const me = useSelector(store => store.auth.user!);
  const centerPoints = useSelector(store => store.partials.centerPoints);

  const handleSaveCenterPoint = (e: google.maps.MouseEvent): void => {
    const userCenterPoint = centerPoints.find(point => point.user === me.id);

    if (userCenterPoint) {
      changeUserCenterPoint.mutate({
        id: userCenterPoint!.id,
        user: userCenterPoint!.user,
        point: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
    } else {
      saveUserCenterPoint.mutate({
        user: me.id,
        point: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
    }
  };

  const handleAddPoint = (e: google.maps.MouseEvent) => {
    const point = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    assertIsDefined(editingPasspointId);
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        //@ts-ignore
        latLng: e.latLng,
      },
      function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            assignOrder({
              point,
              id: editingPasspointId,
              type: "passpoint",
              address: results[0].formatted_address,
              warehouse: null,
              warehouseDeliveryDetails: null,
              hasGuaranteedDeliveryBeforeChristmas: false,
              isAssemblingRequested: false,
              hasCarrying: false,
            });
            actions.setState({ editingPasspointId: null });
          }
        }
      },
    );
  };

  return (e: google.maps.MouseEvent) => {
    if (isSettingCenterPoint) {
      if (!saveUserCenterPoint.isLoading && !changeUserCenterPoint.isLoading) {
        handleSaveCenterPoint(e);
      }
      return;
    }

    if (editingPasspointId) {
      return handleAddPoint(e);
    }
    if (mapMode === "polygon") {
      return polygonController.handleAddPoint(e);
    }
    if (mapMode === "map") {
      return closeInfoWindow();
    }
  };
};

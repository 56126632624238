import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { CreateCorrectionModalState } from "api/trading-documents/models";
import { CreateCorrectionManuallyModal } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { useDrawer } from "hooks/useDrawer";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { DownloadFakir } from "pages/tradingDocuments/shared/components/DownloadFakir";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button/Button";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

export const PanelHeader = () => {
  const { close } = useDrawer("receipts");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const { isTriggerElementHidden } = useScrollableContext();
  const createSalesInvoiceFromReceiptMutation = tradingDocumentsActions.useCreateSalesInvoiceFromReceipt();

  if (!tradingDocument) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} />
            <Typography fontSize="16" fontWeight="700" noWrap>
              Paragon {tradingDocument.signature}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {tradingDocument.invoiceType === "RECEIPT" && tradingDocument.status === "CONFIRMED" && (
            <DesignSystemButton
              className="text-uppercase"
              disabled={tradingDocument.isConvertedToSalesDocument}
              isLoading={createSalesInvoiceFromReceiptMutation.isLoading}
              onClick={() => createSalesInvoiceFromReceiptMutation.mutate(tradingDocument.id)}
              size="small"
              startIcon={tradingDocument.isConvertedToSalesDocument ? MdiCheck : MdiAdd}
              variant="gray"
            >
              {tradingDocument.isConvertedToSalesDocument
                ? "Przekształcono"
                : "Przekształć do faktury sprzedażowej"}
            </DesignSystemButton>
          )}
          <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
          <DownloadRevisor tradingDocumentIds={[tradingDocument.id]} />
          <DownloadFakir tradingDocumentIds={[tradingDocument.id]} />
          <MoreInfoButton close={close} invoice={tradingDocument} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
        type="RECEIPT"
      />
    </>
  );
};

import { GoogleMap, InfoWindow, Polyline } from "@react-google-maps/api";
import passpointMarker from "assets/images/mapMarkers/p55.png";
import pinnedAwaitingMarker from "assets/images/mapMarkers/awaiting/pinnedAwaitingIcon.svg";
import { useMemo, useRef, useState } from "react";
import * as React from "react";
import { useRouteViewState } from "../routeCreatorState";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { DragPolygon, PolygonController } from "./DragPolygon";
import { InfoWindowContent } from "./infoWindowContent/InfoWindowContent";
import styles from "./Map.module.css";
import { Marker } from "./Marker";
import { MarkerCluster } from "./MarkerCluster";
import { StartingPointMarker } from "./startingPointMarker";
import { OrderPoint } from "api/orders/models";
import { Route } from "api/routes/models";
import { MapMode } from "../Creator";
import { getMarkerIcon, getPinnedMarkerIcon } from "utilities/getMarkerIcon";
import { useSelector } from "hooks";
import { useOnMapClick } from "./hooks/useOnMapClick";
import { WarehouseIconKind } from "api/wms/models";
import { useMapTheme } from "../hooks/useMapTheme";

interface Props {
  polygonController: PolygonController;
  mapMode: MapMode;
  route: Route;
  routePoints: OrderPoint[];
  routePointsSearch: string;
}

export interface RoutePoint {
  id: string | number;
  type: "order" | "passpoint";
  point: { lat: number; lng: number };
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  isAssemblingRequested: boolean;
  hasCarrying: boolean;
  warehouse: { id: number; icon: WarehouseIconKind } | null;
  isHidden: boolean;
  icon: {
    url: string;
    anchor: google.maps.Point;
  };
  label:
    | {
        text: string;
        color: string;
        fontSize: string;
        fontWeight: string;
      }
    | undefined;
  isPinned: boolean;
  warehouseDeliveryDetails: any;
}

export const Map = ({
  polygonController,
  mapMode,
  route,
  routePoints,
  routePointsSearch,
}: Props) => {
  const zoom = useRef(7);
  const { theme } = useMapTheme();
  const showOnlyRoutePoints = useRouteViewState("slave", store => store.showOnlyRoutePoints);
  const isGraphhopperPolylineVisible = useRouteViewState(
    "slave",
    store => store.isGraphhopperPolylineVisible,
  );
  const isSettingCenterPoint = useRouteViewState("slave", store => store.isSettingCenterPoint);
  const isLoading = useRouteViewState("slave", store => store.isLoading);
  const { openedInfoWindowData, closeInfoWindow } = useInfoWindow();
  const graphhopperPolyline = useRouteViewState("slave", store => store.graphhopperPolyline);
  const isPointPolylineVisible = useRouteViewState("slave", store => store.isPointPolylineVisible);
  const [span, setSpan] = useState({ south: 0, north: 0, west: 0, east: 0 });
  const spanLoaded = useRef(false);
  const map = useRef<google.maps.Map>();
  const centerPoints = useSelector(store => store.partials.centerPoints);
  const me = useSelector(store => store.auth.user!);
  const onMapClick = useOnMapClick({ route, mapMode, polygonController });

  const centerPoint = useMemo(() => {
    const userCenterPoint = centerPoints.find(point => point.user === me.id);
    if (userCenterPoint) return userCenterPoint.point;
    return route.startingPoint.point;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allRoutePoints: RoutePoint[][] = useMemo(() => {
    const latLngToPointsDict: Record<string, RoutePoint[]> = {};

    if (!showOnlyRoutePoints) {
      routePoints.forEach(routePoint => {
        const key = routePoint.point.lat + "_" + routePoint.point.lng;
        const currentLatLng = latLngToPointsDict[key] || [];
        if (!currentLatLng.find(order => order.id === routePoint.id)) {
          const toAdd: RoutePoint = {
            id: routePoint.id,
            warehouse: routePoint.warehouse,
            isPinned: false,
            hasGuaranteedDeliveryBeforeChristmas: routePoint.hasGuaranteedDeliveryBeforeChristmas,
            isAssemblingRequested: routePoint.isAssemblingRequested,
            hasCarrying: routePoint.hasCarrying,
            isHidden: routePoint.isHidden,
            point: routePoint.point,
            type: "order",
            label: undefined,
            icon: {
              url: getMarkerIcon(routePoint),
              anchor: { x: 11, y: 11 } as google.maps.Point,
            },
            warehouseDeliveryDetails: routePoint.warehouseDeliveryDetails,
          };
          latLngToPointsDict[key] = [...currentLatLng, toAdd];
        }
      });
    }

    let currentOrderNum = 0;
    route.ordersPositions
      .filter(el => el.meta.point)
      .forEach(el => {
        const key = el.meta.point!.lat + "_" + el.meta.point!.lng;
        const alreadyAddedOrder = latLngToPointsDict[key]?.find(
          routePoint => String(routePoint.id) === el.id,
        );

        const pointToAddIcon = (): string => {
          if (el.type === "order") {
            if (
              el.warehouseDeliveryDetails &&
              !el.warehouseDeliveryDetails.isInWarehouse &&
              el.warehouseDeliveryDetails.date !== null
            ) {
              return pinnedAwaitingMarker;
            } else {
              const order = route.orders.find(order => String(order.id) === el.id);
              return getPinnedMarkerIcon(order?.warehouse?.icon || WarehouseIconKind.ROUND);
            }
          } else {
            return passpointMarker;
          }
        };

        if (alreadyAddedOrder) {
          alreadyAddedOrder.isPinned = true;
          return;
        }
        if (el.type === "order") {
          currentOrderNum = currentOrderNum + 1;
        }
        const toAdd: RoutePoint = {
          id: el.id,
          isPinned: true,
          isHidden: false,
          type: el.type === "passpoint" ? "passpoint" : "order",
          point: el.meta.point!,
          hasGuaranteedDeliveryBeforeChristmas:
            el.type === "order" ? el.hasGuaranteedDeliveryBeforeChristmas : false,
          warehouse: el.type === "order" ? el.meta.warehouse : null,
          isAssemblingRequested: el.type === "order" ? el.meta.isAssemblingRequested : false,
          hasCarrying: el.type === "order" ? el.meta.hasCarrying : false,
          label:
            el.type === "order"
              ? {
                  text: String(currentOrderNum),
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "600",
                }
              : undefined,
          icon: {
            url: pointToAddIcon(),
            anchor:
              el.type === "order"
                ? ({ x: 11, y: 11 } as google.maps.Point)
                : ({ x: 11, y: 11 } as google.maps.Point),
          },
          warehouseDeliveryDetails: el.type === "order" ? el.warehouseDeliveryDetails : null,
        };

        const currentLatLng = latLngToPointsDict[key] || [];

        latLngToPointsDict[key] = [...currentLatLng, toAdd];
      });

    return Object.values(latLngToPointsDict);
  }, [route.orders, route.ordersPositions, routePoints, showOnlyRoutePoints]);

  function handleZoom() {
    if (!map.current) return;
    const zoomLevel = map.current.getZoom();
    zoom.current = zoomLevel;
    const mapSpan = map.current.getBounds();
    if (mapSpan) {
      setSpan(mapSpan.toJSON());
    }
  }

  function onLoad(instance: google.maps.Map<Element>) {
    map.current = instance;
    google.maps.event.addListener(instance, "tilesloaded", () => {
      if (spanLoaded.current === false) {
        const mapSpan = instance.getBounds();
        if (mapSpan) {
          setSpan(mapSpan.toJSON());
        }
        spanLoaded.current = true;
      }
    });
  }

  const newPolylineData = React.useMemo(() => {
    const points = route.ordersPositions.filter(el => el.meta.point).map(loc => loc.meta.point!);
    return [route.startingPoint.point].concat(points || []);
  }, [route.ordersPositions, route.startingPoint]);

  if (!route.startingPoint) {
    return (
      <div className={styles.panel}>
        <h1>Brak punktu startowego</h1>
        <span>Dodaj punkt startowy, by stworzyć trasę</span>
      </div>
    );
  }

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={{
        height: "100vh",
      }}
      zoom={7}
      onZoomChanged={handleZoom}
      options={{
        disableDefaultUI: true,
        styles: (theme as google.maps.MapTypeStyle[]) || [],
      }}
      center={centerPoint}
      onClick={onMapClick}
    >
      {!isSettingCenterPoint && (
        <>
          {isPointPolylineVisible && (
            <Polyline
              path={newPolylineData}
              options={{
                strokeColor: "#765de3",
                strokeWeight: 4,
              }}
            />
          )}

          {isGraphhopperPolylineVisible && (
            <Polyline
              path={graphhopperPolyline}
              options={{
                strokeColor: "#ff5961",
                strokeWeight: 4,
              }}
            />
          )}

          <StartingPointMarker startingPoint={route.startingPoint} />

          {allRoutePoints.map((locations, index) => {
            if (locations.length === 1) {
              const routePoint = locations[0];
              return (
                <Marker
                  index={index}
                  key={routePoint.id}
                  route={route}
                  routePoint={routePoint}
                  isLoading={isLoading}
                />
              );
            } else {
              return (
                <MarkerCluster
                  key={locations[0].point.lat + "_" + locations[0].point.lng}
                  cluster={locations}
                  isLoading={isLoading}
                  route={route}
                  span={span}
                  zoom={zoom.current}
                />
              );
            }
          })}

          {openedInfoWindowData && (
            <InfoWindow
              key={openedInfoWindowData.id}
              position={openedInfoWindowData.point}
              onCloseClick={closeInfoWindow}
            >
              <InfoWindowContent
                id={String(openedInfoWindowData.id)}
                route={route}
                routePointsSearch={routePointsSearch}
              />
            </InfoWindow>
          )}

          <DragPolygon
            path={polygonController.path}
            onDrag={polygonController.onDrag}
            onDoubleClick={polygonController.deletePoint}
            mode={mapMode}
          />
        </>
      )}
    </GoogleMap>
  );
};

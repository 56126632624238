export enum PriorityChoices {
  NORMAL = "NORMAL",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export enum OrderTypeChoices {
  STANDARD = "STANDARD",
  COMPLAINT = "COMPLAINT",
}

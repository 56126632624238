import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import styles from "./Header.module.css";
import { useQuery, useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { SchemaSearch } from "../../schemaSearch/SchemaSearch";

export const Header = () => {
  const halls = useSelector(store => store.partials.halls);
  const { query, updateQuery } = useQuery();
  const { hallId } = query;
  const selectedHall = halls.find(hall => hall.id === Number(hallId));
  const warehouseHalls = halls.filter(hall => hall.warehouse === selectedHall?.warehouse);

  return (
    <div
      className={cx(
        "d-flex align-items-center justify-content-between borderBottomB px-3 pt-2",
        styles.header,
      )}
    >
      <div className="d-flex align-items-center">
        <Typography fontSize="20" fontWeight="700" color="neutralWhite100" className="pr-3">
          Magazyn
        </Typography>
        <SchemaSearch textFieldProps={{ containerClassName: styles.input }} />
      </div>
      <div className="d-flex align-items-center gap-4">
        <Select
          items={warehouseHalls.map(hall => ({
            text: hall.name,
            type: MenuItemType.TEXT,
            value: hall.id,
          }))}
          onChange={hall => {
            if (hall) {
              updateQuery({ hallId: Number(hall) });
            }
          }}
          selected={query["hallId"] || null}
          theme="dark"
        />
      </div>
    </div>
  );
};

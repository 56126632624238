import crossImg from "assets/images/11.svg";
import availabilityStatusIcon from "assets/images/176.svg";
import carImg from "assets/images/24.svg";
import furnitureImg from "assets/images/33.svg";
import pin2Img from "assets/images/42.svg";
import commentImg from "assets/images/45.svg";
import timeImg from "assets/images/46.svg";
import dollarIcon from "assets/images/monetizationOn.svg";
import checkImg from "assets/images/7c.svg";
import redCrossIcon from "assets/images/11r.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AvailabilityDetailsModal } from "components/common/availabilityDetailsModal";
import { getLabelColor } from "components/common/listRow/utils";
import { CommonError, Label, Spinner } from "components/utils";
import { useStateModal } from "hooks";
import { useOrderForRoute } from "hooks/apiPrimitives";
import { Link } from "react-router-dom";
import { dateFns, getAnyErrorKey, pluralize } from "utilities";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import styles from "../infoWindowContent/InfoWindowContent.module.css";
import { getAwaitingLabelColor } from "./utils";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { areConfirmedHoursInCollectionRange } from "../../leftPanel/pointsList/utils/areConfirmedHoursInCollectionRange";
import { isAwaitingForWarehouseDelivery } from "../../utils";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  id: string;
}

export const EmptyInfoWindowContent = ({ id }: Props) => {
  const { data: order, isLoading: inProgress, error } = useOrderForRoute(id);

  const modal = useStateModal();

  if (error) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <CommonError text={getAnyErrorKey(error)} />
        </div>
      </div>
    );
  }

  if (inProgress) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100 pt-5">
          <Spinner color="blue" size="big" />
        </div>
      </div>
    );
  }

  if (!order) return null;

  return (
    <div className={styles["map-point-cloud"]}>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className={styles["point-image"]}>
              <img
                src={getMarkerIcon({
                  ...order,
                  hideOnMapTo: order.delivery.hideOnMapTo,
                  isHidden: order.delivery.isHidden,
                  hideUntilIssueIsSolved: order.delivery.hideUntilIssueIsSolved,
                })}
                alt=""
              />
            </div>
            <div>
              {isAwaitingForWarehouseDelivery(order) &&
              order.warehouseDeliveryDetails.daysLeftToDelivery !== null ? (
                <Label
                  color={getAwaitingLabelColor(order.warehouseDeliveryDetails.daysLeftToDelivery)}
                  className="mr-3 ml-2"
                >
                  {order.warehouseDeliveryDetails.daysLeftToDelivery === 0 ? (
                    <span>dzisiaj</span>
                  ) : (
                    <span>
                      {order.warehouseDeliveryDetails.daysLeftToDelivery}{" "}
                      {pluralize.pl(order.warehouseDeliveryDetails.daysLeftToDelivery, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  )}
                </Label>
              ) : (
                <div className="mr-3 ml-2">
                  <Tag.WithCustomColor
                    label={`${
                      order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate
                    } ${pluralize.pl(
                      order.type === "STANDARD"
                        ? order.leftDays
                        : order.numberOfDaysFromCreatedDate,
                      {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      },
                    )}`}
                    textColor="neutralBlack100"
                    backgroundColor={getLabelColor(
                      order.type === "STANDARD"
                        ? order.leftDays
                        : order.numberOfDaysFromCreatedDate,
                      order.type,
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <small className={styles.createdDate}>
            zam. dn.: {dateFns.format(new Date(order.created), "dd.MM.yyyy")}
          </small>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 mt-2 row">
        <div className="col-6 pl-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={pin2Img} alt="Pin icon" />
              <span>
                {!order.customer
                  ? `${order.delivery.firstName} ${order.delivery.lastName}`
                  : order.customer.hasDropShipping
                  ? `${order.delivery.firstName} ${order.delivery.lastName} (${order.customer.name})`
                  : order.customer.name}
              </span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.delivery.street} <br /> {order.delivery.city} {order.delivery.postCode}
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>tel.: {order.delivery.phone}</div>
          </div>
          {order.payment.type === "CASH_ON_DELIVERY" && (
            <div className="d-flex align-items-center gap-2 mb-3 pl-4">
              <img alt="płatność:" src={dollarIcon} style={{ height: "16px", width: "16px" }} />
              <span className="fs-14 text-color-grey">POBRANIE</span>
            </div>
          )}
        </div>
        <div className="col-6 pr-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={commentImg} alt="Komentarz" />
              <span>Komentarze</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.comments.length ? (
                order.comments.map(comment => (
                  <div key={comment.id}>
                    <div className={styles.hasComment}>{comment.body}</div>
                    <div className={styles.usersComment}>
                      <strong className="mr-2">
                        {comment.user.firstName} {comment.user.lastName}
                      </strong>
                      <span className="mr-2">
                        {dateFns.format(new Date(comment.created), "d MMM yyyy")}
                      </span>
                      <span>{dateFns.format(new Date(comment.created), "H:mm")}</span>
                    </div>
                  </div>
                ))
              ) : (
                <span className={styles.noComment}>Brak komentarzy</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 row">
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pl-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={carImg} alt="Samochód" />
            <span>Przewidywany czas dostawy</span>
          </div>
          <div
            className={cx(styles["map-cloud_group-data"], {
              [styles.correctConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === true,
              [styles.invalidConfirmationRange]:
                order.delivery.maximumCollectionHour &&
                order.delivery.minimumCollectionHour &&
                areConfirmedHoursInCollectionRange(
                  order.delivery.maximumCollectionHour,
                  order.delivery.minimumCollectionHour,
                  order.maximumCollectionHourAtConfirmation,
                  order.minimumCollectionHourAtConfirmation,
                ) === false,
            })}
          >
            {order.collectionHours ? (
              <>
                <span>{order.collectionHours.minimumCollectionHour || "--:--"}</span>
                <span className="mx-2">-</span>
                <span>{order.collectionHours.maximumCollectionHour || "--:--"}</span>
              </>
            ) : (
              <>
                <span className={styles.noComment}>--:--</span>
                <span className="mx-2">-</span>
                <span className={styles.noComment}>--:--</span>
              </>
            )}
          </div>
        </div>
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pr-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={timeImg} alt="Czas" />
            <span>Potwierdzony czas dostawy</span>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.minimumCollectionHourAtConfirmation &&
            order.minimumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.minimumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
            <span className="mx-2">-</span>
            {order.maximumCollectionHourAtConfirmation &&
            order.maximumCollectionHourAtConfirmation.length > 0 ? (
              <span>{order.maximumCollectionHourAtConfirmation}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap mt-2">
        <div
          className={cx(styles["map-cloud_data-group"], "map-cloud_data-group--product-list mb-3")}
        >
          {order.hasProducedItems ? (
            <div className={styles.hasProducedItems}>
              <img src={checkImg} alt="Sprawdzenie" className="mr-2" />
              <span>Wszystkie meble są przygotowane</span>
            </div>
          ) : null}
          <div className={cx(styles["map-cloud_group-label"])}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                <img src={furnitureImg} alt="Przedmioty" />
                <span>Dostarczyć zamówienie</span>
              </div>
              {order.hasReservations ? (
                <Label color="blue">zarezerwowano</Label>
              ) : (
                <Label color="pink">Nie zarezerwowano</Label>
              )}
            </div>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.externalId ? (
              <div>
                <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1 mr-1")}>
                  {order.signature}
                </Link>
                <span className="fs-12 text-color-grey">({order.externalId})</span>
              </div>
            ) : (
              <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1")}>
                {order.signature}
              </Link>
            )}
            {order.items.map(item => (
              <div className="product d-flex align-items-center mb-2" key={item.id}>
                <div>
                  <div className="d-flex product-name mr-3">
                    {item.product ? item.product.name : "???"}
                    <div className="d-flex align-items-center ml-1">
                      <img className={styles.xIcon} src={crossImg} alt="" />
                      <span className="ml-1">{item.quantity}</span>
                    </div>
                    <Button
                      type="button"
                      kind="secondary"
                      size="round-s"
                      className="ml-1"
                      onClick={() => modal.open(item.index)}
                      title="Sprawdź dostępność"
                    >
                      <img alt="Dostępnosć" src={availabilityStatusIcon} />
                    </Button>
                  </div>
                  {item.attributes.map(
                    ({ attribute, value }: { attribute: string; value: string }, index: number) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}</strong>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {order.isDeliveryDateRejected && (
        <div className="d-flex flex-wrap m-0 row border-top pt-2">
          <div className={cx(styles["map-cloud_data-group"], "col-12")}>
            <div className={cx(styles["map-cloud_group-label-red"])}>
              <img src={redCrossIcon} alt="Odrzucono" />
              <span>Odrzucono</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"], "ml-4")}>
              {order.deliveryDateRejectedByAvatar ||
              order.deliveryDateRejectedByFirstName ||
              order.deliveryDateRejectedByLastName ||
              order.deliveryDateRejectedTimestamp ? (
                <div className="d-flex align-items-center gap-2 mt-1">
                  <AvatarOrInitials
                    avatarSrc={order.deliveryDateRejectedByAvatar}
                    firstName={order.deliveryDateRejectedByFirstName}
                    lastName={order.deliveryDateRejectedByLastName}
                    className={styles.midAvatar}
                  />
                  <div className="d-flex align-items-center gap-2">
                    <div>
                      {order.deliveryDateRejectedByFirstName} {order.deliveryDateRejectedByLastName}
                    </div>
                    {order.deliveryDateRejectedTimestamp && (
                      <div className="fs-12 text-color-grey">
                        {dateFns.format(
                          new Date(order.deliveryDateRejectedTimestamp),
                          "dd.MM.yyyy, HH:mm",
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <span className={styles.noComment}>--</span>
              )}
            </div>
          </div>
        </div>
      )}
      {modal.isOpen && <AvailabilityDetailsModal stateModal={modal} />}
    </div>
  );
};
